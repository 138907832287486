<template>
    <div class="burger_parent" ref="parent_burger" v-if="userData">
        <Slide right class="burger" :close-on-navigation="true">
            <div class="burger-el">
                <div class="burger__wrapper">
                    <div class="burger__name" @click="routePage('Profile')">

                        <div v-if="!userData.avatar_url" class="profile-image">{{ userData.info.name[0] }}</div>
                        <img v-else class="profile-image"
                             :src="path + userData.avatar_url + '?is_image=1&avatar_size=128'" alt="">

                        <div class="profile-name">{{ userData.info.name }} {{ userData.info.surname }}</div>

                    </div>

                    <div class="burger__button" @click="routePage('Home')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.58398 10.1666H7.41732C9.25065 10.1666 10.1673 9.24992 10.1673 7.41659V5.58325C10.1673 3.74992 9.25065 2.83325 7.41732 2.83325H5.58398C3.75065 2.83325 2.83398 3.74992 2.83398 5.58325V7.41659C2.83398 9.24992 3.75065 10.1666 5.58398 10.1666Z"
                                stroke="#999999" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path
                                d="M16.584 10.1666H18.4173C20.2507 10.1666 21.1673 9.24992 21.1673 7.41659V5.58325C21.1673 3.74992 20.2507 2.83325 18.4173 2.83325H16.584C14.7507 2.83325 13.834 3.74992 13.834 5.58325V7.41659C13.834 9.24992 14.7507 10.1666 16.584 10.1666Z"
                                stroke="#999999" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path
                                d="M16.584 21.1666H18.4173C20.2507 21.1666 21.1673 20.2499 21.1673 18.4166V16.5833C21.1673 14.7499 20.2507 13.8333 18.4173 13.8333H16.584C14.7507 13.8333 13.834 14.7499 13.834 16.5833V18.4166C13.834 20.2499 14.7507 21.1666 16.584 21.1666Z"
                                stroke="#999999" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                            <path
                                d="M5.58398 21.1666H7.41732C9.25065 21.1666 10.1673 20.2499 10.1673 18.4166V16.5833C10.1673 14.7499 9.25065 13.8333 7.41732 13.8333H5.58398C3.75065 13.8333 2.83398 14.7499 2.83398 16.5833V18.4166C2.83398 20.2499 3.75065 21.1666 5.58398 21.1666Z"
                                stroke="#999999" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                        <div class="burger__button-title">Список задач</div>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.5 17.9999L22.0932 13.0605C22.6356 12.4772 22.6356 11.5226 22.0932 10.9393L17.5 5.99988"
                                stroke="#9F9F9F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>

                    </div>

                </div>
            </div>

            <div class="burger_footer">
                <a href="https://t.me/+nWDKpBMj-VhhMDFi" rel="noopener noreferrer" target="_blank" class="burger_footer--el">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2807_10553)">
                            <path
                                d="M11.7188 5.40937C5.48906 7.84219 0.3375 9.86719 0.271875 9.90937C0.103125 10.0219 -1.46265e-08 10.2609 -1.46265e-08 10.5469C-1.46265e-08 11.0344 -0.154688 10.9359 3.28594 12.6562C6.12188 14.0766 6.39375 14.2031 6.60938 14.2031C6.74063 14.2031 6.90469 14.175 6.975 14.1375C7.05 14.1 8.17969 13.4437 9.49219 12.6797C10.8094 11.9156 11.8359 11.3391 11.7797 11.4C11.7188 11.4562 11.0672 12.0422 10.3266 12.7031C9.58594 13.3594 8.93906 13.9594 8.8875 14.0297C8.78906 14.1609 8.78906 14.2031 8.78906 17.8266V21.4922L8.89219 21.6562C9.04219 21.8953 9.25313 22.0031 9.57188 22.0078C9.76875 22.0078 9.87656 21.9797 9.97031 21.9094C10.0734 21.8344 12.4828 18.7031 12.6516 18.4266C12.6844 18.375 13.3828 18.7547 15.8391 20.1562C17.5688 21.1406 19.0547 21.9703 19.1438 21.9937C19.4766 22.0969 19.8656 21.9187 20.0156 21.6C20.1328 21.3422 24 2.03437 24 1.71094C24 1.28437 23.6813 0.979685 23.2406 0.98906C23.0906 0.98906 20.3063 2.05781 11.7188 5.40937ZM20.5031 11.4891C19.5656 16.1625 18.7875 19.9922 18.7781 20.0016C18.7688 20.0109 17.4656 19.2844 15.8813 18.3844C14.3016 17.4844 12.9422 16.7109 12.8578 16.6687C12.6375 16.5562 12.2813 16.5703 12.0891 16.7016C12 16.7578 11.5922 17.2547 11.1797 17.7984C10.7672 18.3469 10.4016 18.8203 10.3734 18.8531C10.3266 18.9 10.3125 18.4969 10.3125 16.8516L10.3172 14.7891L14.6344 10.9687C17.0063 8.86875 18.9891 7.08281 19.0359 7.00781C19.1625 6.80625 19.1531 6.43125 19.0172 6.22969C18.8484 5.98125 18.6328 5.85937 18.3563 5.85937C18.1266 5.85937 17.925 5.97187 12.3563 9.21094L6.59531 12.5625L4.62656 11.5922L2.65781 10.6172L12.4031 6.81094C17.7656 4.72031 22.1625 3.00469 22.1766 3.00469C22.1953 3 21.4406 6.82031 20.5031 11.4891Z"
                                fill="#999999"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_2807_10553">
                                <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>

                    <div class="burger__button-title">Чат с разработчиками</div>
                </a>

                <div class="burger_footer--el" @click="clearAppCache">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.87 5.66998L6.45 7.74999L4.89001 5.19C4.32001 4.25 4.62 3.01 5.56 2.44C6.5 1.87 7.74 2.16998 8.31 3.10998L9.87 5.66998Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.82 9.15997L8.66 11.08C6.82 12.2 6.25999 14.46 7.14999 16.26L9.19999 20.44C9.85999 21.79 11.46 22.26 12.74 21.47L19.17 17.56C20.46 16.78 20.77 15.15 19.88 13.94L17.11 10.2C15.91 8.58001 13.66 8.03997 11.82 9.15997Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.7567 5.09791L5.63208 8.21851L7.71248 11.6349L12.8371 8.51431L10.7567 5.09791Z" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.31 16.8101L15.96 19.5201" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.75 18.37L13.4 21.08" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.87 15.25L18.52 17.96" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>


                    <div class="burger__button-title">Очистить кэш</div>
                </div>

                <div class="burger_footer--el" @click="logoutAccount">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 12H11" stroke="#999999" stroke-width="1.45" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path d="M18 15L20.913 12.087C20.961 12.039 20.961 11.961 20.913 11.913L18 9" stroke="#999999"
                              stroke-width="1.45" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M16 5.41362V4.94317C16 4.16369 15.3284 3.5318 14.5 3.5318H5C3.89543 3.5318 3 4.37432 3 5.41362V18.5864C3 19.6257 3.89543 20.4682 5 20.4682H14.5C15.3284 20.4682 16 19.8363 16 19.0568V18.5864"
                            stroke="#999999" stroke-width="1.45" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <div class="burger__button-title">Выйти</div>
                </div>
                <span class="version">Version: {{version}}</span>
            </div>
        </Slide>
    </div>

</template>
<script>
import {Slide} from 'vue-burger-menu'


export default {
    components: {
        Slide
    },
    data() {
        return {
            path: process.env.VUE_APP_TASK_RADAR,
            name: '',
            toggleBurger: false,
            body: null,
            version: process.env.VUE_APP_VERSION,
        }
    },
    mounted() {
        const tempBody = document.querySelector('body')

        if (tempBody) {
            tempBody.addEventListener('click', e => {
                setTimeout(() => {
                    const bm = document.querySelector('.bm-menu')
                    const headerTimer = document.querySelector('#header__timer__container');

                    if (this.$refs.parent_burger) {
                        if (tempBody.classList.contains('bm-overlay')) {
                            this.$refs.parent_burger.style.width = '100vw'
                            this.$refs.parent_burger.style.height = '100vh'

                            bm.style.width = '80%'
                            if (headerTimer) {
                                headerTimer.style.display = 'none';
                            }
                        } else {
                            this.$refs.parent_burger.style.width = '0'
                            this.$refs.parent_burger.style.height = '0'

                            bm.style.width = '0'
                            if (headerTimer) {
                                setTimeout(() => {
                                    headerTimer.style.display = 'block';
                                }, 190)
                            }
                        }
                    }
                }, 0)

            })
        }
    },
    computed: {
        userData() {
            return this.$store.getters.getUserData
        }
    },
    methods: {
        logoutAccount() {
            const bm = document.querySelector('.bm-menu')
            const tempBody = document.querySelector('body')

            tempBody.classList.remove('bm-overlay')

            if (this.$refs.parent_burger) {
                this.$refs.parent_burger.style.width = '0'
                this.$refs.parent_burger.style.height = '0'

                bm.style.width = '0'
            }

            this.$store.dispatch('exitFromAccount')

            if (this.$route.path !== '/login') {
                this.$router.push('/login')
            }
            this.$emit('logout')
        },
        routePage(pageName) {
            if (this.$router.history.current.name !== pageName) {
                this.$router.push({name: pageName})
            }
        },
        clearAppCache() {
            this.$store.dispatch('clearAppCache');
            window.location.reload();
        }
    }
}
</script>
<style>

.burger__name {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    padding-bottom: 16px;
    margin-bottom: 16px;

    border-bottom: 1px solid var(--divider-color);
}

.profile-image {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;

    border-radius: 50%;
    background-color: var(--primary-light);

    color: var(--primary-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.profile-name {
    display: flex;
    align-items: center;

    margin-left: 12px;

    text-align: left;

    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 19.8px */
}

.burger__button {
    padding: 12px 0;

    display: flex;
    align-items: center;
}

.burger__button-title {
    margin-left: 8px;
    margin-right: auto;

    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.burger {
    z-index: 1;
}

.burger p {
    margin-top: 4px;

    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    opacity: 0.54;
}

.bm-menu {
    background: #ffffff !important;
}

.bm-burger-bars {
    height: 2px !important;
    border-radius: 20% !important;
    background-color: #fff !important;
    width: 20px !important;
}

.burger-el {
    display: flex;
    align-items: center;
    margin-left: 6px;
    margin-bottom: 20px;
}

.burger-el a {
    color: #333 !important;
}

.cross-style {
    top: 14px !important;
    right: 27px !important;
}

.bm-cross {
    background: #009bf1 !important;
    width: 2px !important;
    height: 25px !important;
}

.bm-overlay {
    background: #f5f5f3 !important;
}

.bm-burger-button {
    height: 15px !important;
    top: 50% !important;
    transform: translateY(-50%);
    right: 10px !important;
}

.exit-button {
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 0;

    width: 100%;

    margin-bottom: 12px;
    margin-left: 6px;

    border-bottom: none;

    font-size: 18px;
}

.version {
    position: absolute;
    color: #999999 !important;
    bottom: 5px;
    right: 20px;
    font-size: 10px;
}

.burger__wrapper {
    width: 100%;
}


.burger_parent {
    position: fixed;
    bottom: 0;
    left: 0;

    background-color: rgba(103, 103, 103, 0.5);

    z-index: -1;
}

.bm-item-list {
    display: flex;
    flex-direction: column;

    height: 100%;

    margin-left: 0 !important;
}

.burger_footer {
    position: relative;
    top: -56px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: auto;
    gap: 12px;
}

.burger_footer--el {
    display: flex;
    align-items: center;

    padding: 12px 0;
}

/*.burger__exit {*/
/*    position: absolute;*/
/*    bottom: 10px;*/
/*}*/

</style>
