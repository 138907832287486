<template>
    <main class="wrapper" :style="selectTask ? 'margin: 114px auto' : 'margin: 75px auto;'">
        <div class="task_watch_wrapper" v-if="selectTask">
            <div class="task_watch-title" @click="selectAll">Выбрать все</div>
            <div class="task_watch-title" @click="cancelSelect">Отмена</div>
        </div>

        <div v-if="!emptyTask">
            <div class="task-select">
                <div
                    :class="
                        $store.getters.getTaskSelectedTab === task_selected_tab.my ? 'task-select__button--active' : ''
                    "
                    class="task-select__button"
                    @click="tabFilter(task_selected_tab.my)"
                >
                    Мои задачи
                    <slot v-if="$store.getters.getTaskSelectedTab === task_selected_tab.my && !task_select_loading">
                        <svg width="3" height="2" viewBox="0 0 3 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="1.75" cy="1" r="1" fill="white" />
                        </svg>

                        {{ $store.getters.getMyTasksCount }}
                    </slot>
                </div>

                <div
                    :class="
                        $store.getters.getTaskSelectedTab === task_selected_tab.all ? 'task-select__button--active' : ''
                    "
                    class="task-select__button"
                    @click="tabFilter(task_selected_tab.all)"
                >
                    Все задачи
                    <slot v-if="$store.getters.getTaskSelectedTab === task_selected_tab.all && !task_select_loading">
                        <svg width="3" height="2" viewBox="0 0 3 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="1.75" cy="1" r="1" fill="white" />
                        </svg>
                        {{ $store.getters.getAllTasksCount }}
                    </slot>
                </div>
            </div>

            <div class="task-input__wrapper">
                <form class="box" @submit.prevent="applyFilters">
                    <slot v-if="deepSearchString">
                        <svg
                            @click="deepSearchString = ''"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4 14L14 4"
                                stroke="#009BF1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M14 14L4 4"
                                stroke="#009BF1"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </slot>
                    <input
                        inputmode="search"
                        @blur="applyFilters"
                        v-model="deepSearchString"
                        type="text"
                        placeholder="Поиск по задачам"
                    />
                    <svg
                        @click="applyFilters"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M22 22L18.5 18.5"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </form>
            </div>

            <div class="task-filter">
                <div class="task-filter__set" @click="popoverActive = true">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.5 14.25L9.75 14.25"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.75 14.25L16.5 14.25"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 9L5.25 9"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 9L16.5 9"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M1.5 3.75L9.75 3.75"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.75 3.75L16.5 3.75"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.75 2.25L9.75 5.25"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.75 12.75L9.75 15.75"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.25 7.5L8.25 10.5"
                            stroke="#999999"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div class="task-filter__filter">Фильтры</div>
                </div>
                <slot v-if="date_end || date_start || form_id.length > 0 || is_closed !== null || deepSearchString">
                    <div class="task-filter__reset" @click="resetFilters">Очистить фильтры</div>
                </slot>
            </div>

            <div v-if="task_select_loading">
                <Preloader />
            </div>
            <div v-else-if="isTasksEmpty">
                <p class="task-list__empty">Задач нет!</p>
            </div>
            <slot v-else>
                <div v-for="(value, name) in dataFormated" :key="name">
                    <div>
                        <TaskItemList :value="value" :name="name" @click="openToggleMenu(name)" />

                        <div v-if="value.show" class="task-list-item">
                            <Task
                                v-for="taskMin in value.tasks"
                                :key="taskMin.id"
                                :id="taskMin.id"
                                ref="foo"
                                :selectTask="selectTask"
                                :task-min="taskMin"
                                :selectedIdArr="selectedIdArr"
                                @go-to-full-task="goToFullTask"
                                @setSelectActive="setSelectActive"
                                @addIdInArrForRead="addIdInArrForRead"
                            />
                        </div>
                    </div>
                </div>
            </slot>

            <Popup :active="popoverActive" @setActive="changePopoverStatus">
                <div class="popup_header">
                    <button class="popup_header__reset popover_item" style="text-align: left" @click="resetFilters">
                        Очистить
                    </button>
                    <h2 class="popup_header__title popover_item" style="text-align: center">Фильтры</h2>
                    <div style="text-align: right" class="popover_item" @click="changePopoverStatus(false)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#F6F6F6" />
                            <path
                                d="M7.625 16.7639L16.7639 7.625"
                                stroke="#999999"
                                stroke-width="1.33657"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M16.7639 16.7639L7.625 7.625"
                                stroke="#999999"
                                stroke-width="1.33657"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div class="popup_content">
                    <div class="box popup_box">
                        <slot v-if="deepSearchString">
                            <svg
                                @click="deepSearchString = ''"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M4 14L14 4"
                                    stroke="#009BF1"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M14 14L4 4"
                                    stroke="#009BF1"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </slot>
                        <input
                            inputmode="search"
                            v-model="deepSearchString"
                            type="text"
                            placeholder="Поиск по задачам"
                        />
                        <svg
                            @click="applyFilters"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                stroke="#999999"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M22 22L18.5 18.5"
                                stroke="#999999"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <div class="popup_content">
                    <div class="popup_select">
                        <div class="popup_select__text">Дата</div>
                        <div class="popup_select__flex">
                            <slot v-for="date_preset_item in date_preset_arr">
                                <div
                                    :class="date_preset_item === date_preset ? 'popup_select__timer-active' : null"
                                    @click="selectActiveTime(date_preset_item)"
                                    class="popup_select__timer"
                                >
                                    {{ date_preset_item }}
                                </div>
                            </slot>
                        </div>
                    </div>
                    <div class="popup_calendar">
                        <v-date-picker v-model="date_start" mode="date" style="position: relative">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    placeholder="От"
                                    class="popup_calendar--item"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                                <svg
                                    class="popup_calendar--item-svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 1.5V3.75"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12 1.5V3.75"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M2.625 6.8175H15.375"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M8.99661 10.275H9.00335"
                                        stroke="#9F9F9F"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M6.22073 10.275H6.22747"
                                        stroke="#9F9F9F"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M6.22073 12.525H6.22747"
                                        stroke="#9F9F9F"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </template>
                        </v-date-picker>

                        <v-date-picker v-model="date_end" mode="date" style="position: relative">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    placeholder="До"
                                    class="popup_calendar--item popup_calendar--item-second"
                                    :value="inputValue"
                                    v-on="inputEvents"
                                />
                                <svg
                                    class="popup_calendar--item-svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 1.5V3.75"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12 1.5V3.75"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M2.625 6.8175H15.375"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z"
                                        stroke="#9F9F9F"
                                        stroke-width="1.25"
                                        stroke-miterlimit="10"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M8.99661 10.275H9.00335"
                                        stroke="#9F9F9F"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M6.22073 10.275H6.22747"
                                        stroke="#9F9F9F"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M6.22073 12.525H6.22747"
                                        stroke="#9F9F9F"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </template>
                        </v-date-picker>
                    </div>
                    <button
                        class="popup_header__reset"
                        style="margin-top: 4px; padding-left: 0 !important"
                        v-if="date_start || date_end || date_preset"
                        @click="resetDate"
                    >
                        Очистить дату
                    </button>
                </div>

                <div class="popup_content">
                    <div class="popup_select">
                        <div class="popup_select__text">Форма</div>
                        <select multiple name="select" class="task-input" v-model="form_id">
                            <slot v-for="form in allForms">
                                <option v-bind:value="form.id">{{ form.name }}</option>
                            </slot>
                        </select>
                    </div>
                </div>

                <div class="popup_content">
                    <div class="popup_select">
                        <div class="popup_select__text">Завершенные</div>
                        <select name="select" class="task-input" v-model="is_closed">
                            <option v-bind:value="null">Все</option>
                            <option v-bind:value="1">Только завершенные</option>
                            <option v-bind:value="0">Без завершенных</option>
                        </select>
                    </div>
                </div>

                <div class="popup__last-child">
                    <div class="popup_checkbox">
                        <div class="checkbox">
                            <input
                                class="custom-checkbox"
                                type="checkbox"
                                id="only_my"
                                name="only_my"
                                v-model="my_task_bool"
                            />
                            <label for="only_my">Показать только мои задачи</label>
                        </div>
                    </div>
                </div>

                <button @click="applyFilters" class="popup_accept">Применить</button>
            </Popup>
        </div>

        <ErrorMessage v-if="emptyTask" :mess="nameErrore" />

        <div class="watch_all" v-if="selectTask" @click="readTasks">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.9833 10.0544C12.9833 11.7044 11.6499 13.0377 9.99993 13.0377C8.34993 13.0377 7.0166 11.7044 7.0166 10.0544C7.0166 8.40438 8.34993 7.07104 9.99993 7.07104C11.6499 7.07104 12.9833 8.40438 12.9833 10.0544Z"
                    stroke="#009BF1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.0001 16.9462C12.9418 16.9462 15.6834 15.2129 17.5918 12.2129C18.3418 11.0379 18.3418 9.06287 17.5918 7.88787C15.6834 4.88787 12.9418 3.15454 10.0001 3.15454C7.05845 3.15454 4.31678 4.88787 2.40845 7.88787C1.65845 9.06287 1.65845 11.0379 2.40845 12.2129C4.31678 15.2129 7.05845 16.9462 10.0001 16.9462Z"
                    stroke="#009BF1"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>

            Просмотреть задачи
        </div>
    </main>
</template>

<script>
import Task from '@/components/TaskItem.vue';
import ErrorMessage from '@/components/messages/ErrorMessage.vue';
import { openActiveTaskList, sortTaskByDate } from '@/utils/task';
import TaskItemList from '@/components/TaskItemList';
import Popup from '@/shared/ui/popup/Popup';
import { task_selected_tab } from '@/constants/constants';
import Preloader from '@/shared/ui/preloader';
import { eventBus } from '@/main';
import { TaskApi } from '@/shared/api';
import { TaskListSelectMixin } from '@/mixin/TaskListSelectMixin';
import { TaskListMethodMixin } from '@/mixin/TaskListMethodMixin';
import { LocalStorageService } from '@/utils/storage';
import { checkWhenSwitchTaskTab, getTasksById } from '@/utils/storage/storage-tasklist-methods';
import { STORAGE_KEY } from '@/utils/const';

export default {
    name: 'TaskList',
    components: {
        Preloader,
        Popup,
        TaskItemList,
        Task,
        ErrorMessage,
    },
    props: {
        tasksObjectProps: {
            type: Array,
            required: false,
        },
        updatedTaskId: {
            type: Object,
        },
        task: {
            type: Object,
            required: false,
        },
        is_task_updated: {
            type: Boolean,
        },
    },
    mixins: [TaskListSelectMixin, TaskListMethodMixin],
    data() {
        return {
            data: [],
            date: new Date(),
            dataFormated: {},
            popoverActive: false,
            search: '',
            emptyTask: false,
            nameErrore: '',
            taskSelected: null,
            deepSearchString: this.$store.getters.getSearchTask ?? '',
            date_start: this.$store.getters.getStartTime ? this.$store.getters.getStartTime : null,
            date_end: this.$store.getters.getEndTime ? this.$store.getters.getEndTime : null,
            date_preset: this.$store.getters.getPickerTime ?? null,
            date_preset_arr: ['Вчера', 'Сегодня', 'Завтра'],
            form_id: this.$store.getters.getFormsId,
            is_closed: this.$store.getters.getIsClosedTask ?? null,
            allForms: this.$store.getters.getAllForms,
            my_task_bool: this.$store.getters.getIsMyTasksBool ?? false,
            task_selected_tab: task_selected_tab,
            task_select_loading: false,
            abortControllerTab: null,
            isFirstTaskLoad: true,
            tasksObject: this.tasksObjectProps,
            isTasksEmpty: false,
        };
    },
    created() {
        this.taskListRender();
    },
    async mounted() {
        eventBus.$off('socketEvent');
        eventBus.$off('socketEventTaskList');

        eventBus.$on('socketEventTaskList', async (data) => {
            if (this.$route.name === 'FoundTasks') return;

            if (this.$route.name !== 'Home' && this.$route.name !== 'TaskFull' && data.show_popup) {
                this.updateTaskBySocket();
                return;
            }
            await this.changeTaskBySocket(data);
        });

        const tab = this.$store.getters.getTaskSelectedTab ? this.$store.getters.getTaskSelectedTab : 'my';
        await this.tabFilter(tab, true);

        await this.$store.dispatch('addTaskSelected', null);

        if (this.$refs.foo) {
            for (let i = 0; i < this.$refs.foo.length; i++) {
                if (this.taskSelected === this.$refs.foo[i].taskMin.id) {
                    this.$refs.foo[i].isTaskSelected = true;
                    let offset = this.$refs.foo[i].$el.getBoundingClientRect().top + window.pageYOffset - 350;
                    window.scrollTo({ top: offset, behavior: 'smooth' });
                }
            }
        }

        this.deepSearchString = this.$store.getters.getSearchTask;
    },
    destroyed() {
        this.abort();
        this.isAborted = true;
    },
    computed: {
        value() {
            return this.data;
        },
    },
    watch: {
        '$store.getters.getRerenderList'() {
            this.taskListRender();
        },
        getTaskSelected: function (newValue) {
            this.taskSelected = newValue;
        },
        async popoverActive() {
            if (this.popoverActive) {
                if (this.$store.getters.getAllForms.length === 0) {
                    await this.$store.dispatch('addAllForms');
                    this.allForms = this.$store.getters.getAllForms;
                }
            }
        },
        updatedTaskId: {
            handler(newValue) {
                this.tasksObject = newValue;
                this.taskListRender();
            },
            deep: true,
        },
        date_start() {
            if (!this.date_end) {
                this.date_end = this.date_start;
            }
        },
        date_end() {
            if (!this.date_start) {
                this.date_start = this.date_end;
            }
        },
        async '$store.getters.getOnlineStatus'(value) {
            if (value) {
                try {
                    let user = this.$store.getters.getCurrentUser;
                    const tab = this.$store.getters.getTaskSelectedTab;
                    if (!user || !tab) return;

                    const response = await TaskApi.getTasks(user, tab, this.abortController.signal);

                    await this.$store.dispatch('rerenderTasksList', false);
                    await this.$store.dispatch('addTasks', {
                        tasks: response,
                        tab: tab,
                    });
                    await this.$store.dispatch('rerenderTasksList', true);
                    this.dataFormated = sortTaskByDate(response);

                    // добавил проверку, чтобы открытие группы задач происходило только при первом рендере или если группа на сегодня открыта
                    this.isTaskPasses(this.dataFormated, this.isFirstTaskLoad);
                    this.stayListWithTaskOpen();
                } catch (err) {
                    console.error('An error occurred:', err);
                }
            }
        },
    },
    methods: {
        taskListRender() {
            this.taskSelected = this.$store.getters.getTaskSelected;

            this.dataFormated = sortTaskByDate(this.tasksObject);

            this.isTaskPasses(this.dataFormated, this.isFirstTaskLoad);
        },
        resetFilters() {
            this.resetDate();
            // Очищает фильтры
            this.form_id.length = 0;
            this.my_task_bool = false;
            this.is_closed = null;
            this.deepSearchString = '';

            this.$store.dispatch('addFilters', {
                form_id: this.form_id,
                closed_task: this.is_closed,
                my_task_bool: this.my_task_bool,
                searchTask: this.deepSearchString,
            });
        },

        // Обновление последнего комментария, если сообщение отправлено в фоне
        updateLastTaskMessage(syncMessageObj) {
            for (const [_, value] of Object.entries(this.dataFormated)) {
                value.tasks = value.tasks.map((item) => {
                    if (item && item.id) {
                        if (item.id === Number(syncMessageObj.taskId)) {
                            item.comment = syncMessageObj.message;
                            item.unread_comments += 1;
                        }
                    }

                    return item;
                });
            }
        },

        async changeTaskBySocket(msg) {
            // Обновляю последний комментарий и количество непрочитанных сообщений
            if (msg.data.type === 'comment') {
                for (const [_, value] of Object.entries(this.dataFormated)) {
                    value.tasks = value.tasks.map((item) => {
                        if (item && item.id) {
                            if (item.id === msg.data.task_id) {
                                item.comment = msg.data.text;
                                item.unread_comments += 1;
                            }
                        }

                        return item;
                    });
                }
            }

            // Если изменение маршрутизации и задача есть в списке, тяну все задачи
            if (
                msg.data.type === 'system' &&
                (msg.data.info.action === 'routing' ||
                    msg.data.info.action === 'change_spectators' ||
                    msg.data.info.action === 'change_approvers')
            ) {
                let isTaskInList = false;
                for (const [_, value] of Object.entries(this.dataFormated)) {
                    isTaskInList = value.tasks.some((item) => item && item.id && item.id === msg.data.task_id);

                    if (isTaskInList) {
                        break;
                    }
                }

                // Если добавление и задача не в списке
                if (msg.data.info.kind === 'add') {
                    if (!isTaskInList) {
                        this.updateTaskBySocket();
                    }
                } else {
                    // Если удаление и задача в списке
                    if (isTaskInList) {
                        this.updateTaskBySocket();
                    }
                }
            }

            if (
                msg.data.type === 'system' &&
                (msg.data.info.action === 'task_opened' || msg.data.info.action === 'task_closed')
            ) {
                for (const [_, value] of Object.entries(this.dataFormated)) {
                    value.tasks = value.tasks.map((item) => {
                        if (item && item.id) {
                            if (item.id === msg.data.task_id) {
                                item.closed = msg.data.info.action !== 'task_opened';
                            }
                        }

                        return item;
                    });
                }
            }

            // Ничего не делать при пересогласовании
            if (msg.data.type === 'system' && msg.data.info.action === 'task_revoke_approves') {
                return;
            }

            // Получаю новую задачу по сокету
            if (
                this.$route.name === 'Home' &&
                msg.data.type === 'system' &&
                (msg.data.info.action === 'created' || msg.data.info.action === 'change_values')
            ) {
                this.updateTaskBySocket();
            }
        },
        async applyFilters() {
            // Применяет фильтры и открывает новую страницу FoundTasks где будет сделан запрос с этими данными
            await this.$store.dispatch('addFilters', {
                date_start: this.date_start ? this.date_start : null,
                date_end: this.date_end ? this.date_end : null,
                form_id: this.form_id,
                closed_task: this.is_closed,
                my_task_bool: this.my_task_bool,
                searchTask: this.deepSearchString,
                date_preset: this.date_preset,
            });

            if (
                this.date_end ||
                this.date_start ||
                this.form_id.length > 0 ||
                this.is_closed !== null ||
                this.deepSearchString ||
                this.my_task_bool
            ) {
                const currentRouteName = this.$route.name;

                // Проверка, является ли текущий маршрут 'FoundTasks'
                if (currentRouteName !== 'FoundTasks') {
                    try {
                        // Выполнение навигации только если текущий маршрут не 'FoundTasks'
                        await this.$router.push({ name: 'FoundTasks' });
                    } catch (error) {
                        // Обработка ошибок навигации
                        console.error('Error navigating to FoundTasks:', error);
                    }
                }
            } else {
                this.popoverActive = false;
            }
        },
        updateTaskBySocket() {
            const user = this.$store.getters.getCurrentUser;
            const tab = this.$store.getters.getTaskSelectedTab;

            this.abort();

            try {
                TaskApi.getTasks(user, tab, this.abortController.signal).then((response) => {
                    if (response && !response.error) {
                        this.$store.dispatch('addTasks', {
                            tasks: response,
                            tab: tab,
                        });
                        this.dataFormated = sortTaskByDate(response);
                        this.isTaskPasses(this.dataFormated, this.isFirstTaskLoad);
                        this.stayListWithTaskOpen();
                    }
                });
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch request aborted');
                } else {
                    console.error('Fetch request failed:', error);
                }
            } finally {
                this.isRequestShouldCancelled = false;
            }
        },
        async tabFilter(filter_type, ignoreCondition = false) {
            try {
                // При изменении типа сортировки (мои, все)
                // Запрашивает по этому типу данные
                // И сортирует их в группы по датам
                if (filter_type === this.$store.getters.getTaskSelectedTab && !ignoreCondition) {
                    return;
                }

                let user = this.$store.getters.getCurrentUser;
                this.task_select_loading = true;

                // Отменяем предыдущий запрос, если он есть
                if (this.abortControllerTab) {
                    this.abortControllerTab.abort();
                }

                // Создаем новый контроллер прерывания для текущего запроса
                this.abortControllerTab = new AbortController();

                await this.$store.dispatch('changeTaskSelectedTab', filter_type);

                const preloadTasks = LocalStorageService.getInstance().getArray(STORAGE_KEY.taskList);

                if (filter_type === task_selected_tab.all) {
                    await this.fetchAllTasks(user, preloadTasks, this.abortControllerTab.signal);
                } else {
                    await this.fetchMyTasks(user, preloadTasks, this.abortControllerTab.signal);
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch request aborted');
                } else {
                    console.error('Fetch request failed:', error);
                }
            } finally {
                this.isRequestShouldCancelled = false;
            }
        },

        async fetchAllTasks(user, preloadTasks, signal) {
            try {
                // Если все задачи еще не были прогружены, то ничего не делаем
                let cacheData = null;
                if (preloadTasks.all_tasks_id) {
                    cacheData = checkWhenSwitchTaskTab(preloadTasks.timestamp, preloadTasks.all_tasks_id);

                    if (cacheData && cacheData.length > 0) {
                        // Если задачи вернулись
                        await this.$store.dispatch('changeAllTasksCount', cacheData.length);

                        const response = getTasksById(preloadTasks.tasks, cacheData);

                        if (JSON.parse(localStorage.getItem('task_selected_tab')) !== task_selected_tab.all) return;

                        this.loadingComplete(response);
                        this.stayListWithTaskOpen();
                        this.isFetchingAllTaskList = false;
                    }
                }

                // if (this.$store.getters.getAllTaskUpdatedValue) return;

                const response = await TaskApi.getTasks(user, task_selected_tab.all, { signal });

                console.log(localStorage.getItem('task_selected_tab'), ' fetchAllTasks');

                if (JSON.parse(localStorage.getItem('task_selected_tab')) !== task_selected_tab.all) return;

                console.log('next all');

                // Проверяем, был ли запрос прерван
                if (signal.aborted) {
                    console.log('Fetch request for "all" tasks was aborted');
                    return;
                }

                if (response && !response.error) {
                    console.log('ЗАПРОС всех ЗАДАЧ !!!!!', response);
                    this.loadingComplete(response);
                    this.stayListWithTaskOpen();
                    await this.$store.dispatch('addTasks', { tasks: response, tab: 'all' });
                    await this.$store.dispatch('changeAllTasksCount', response.length);

                    await this.$store.dispatch('changeAllTaskUpdatedValue', true);
                    this.isFirstTaskLoad = false;
                }
            } catch (error) {
                // Обрабатываем ошибку
                if (error.name === 'AbortError') {
                    console.log('Fetch request aborted');
                } else {
                    console.error('Fetch request failed:', error);
                }
            }
        },

        async fetchMyTasks(user, preloadTasks, signal) {
            console.log('fetchMyTasks');
            try {
                // Получение задач из кэша
                const cacheData = checkWhenSwitchTaskTab(preloadTasks.timestamp, preloadTasks.tasks_id);

                // Если задачи вернулись
                if (cacheData && cacheData.length > 0) {
                    await this.$store.dispatch('changeMyTasksCount', cacheData.length);

                    const response = getTasksById(preloadTasks.tasks, cacheData);

                    if (JSON.parse(localStorage.getItem('task_selected_tab')) !== task_selected_tab.my) return;

                    this.loadingComplete(response);
                    this.stayListWithTaskOpen();
                    this.isFetchingMyTaskList = false;

                    // // Проверка обновления задачи
                    // if (this.$store.getters.getMyTaskUpdatedValue) {
                    //     return;
                    // }
                }

                const response = await TaskApi.getTasks(user, task_selected_tab.my, { signal });

                console.log(localStorage.getItem('task_selected_tab'), ' fetchMyTasks');

                if (JSON.parse(localStorage.getItem('task_selected_tab')) !== task_selected_tab.my) return;

                console.log('next my');

                // Проверяем, был ли запрос прерван
                if (signal.aborted) {
                    console.log('Fetch request for "my" tasks was aborted');
                    return;
                }

                if (response && !response.error) {
                    this.stayListWithTaskOpen();
                    this.loadingComplete(response);

                    this.isFirstTaskLoad = false;
                    await this.$store.dispatch('addTasks', { tasks: response, tab: 'my' });
                    await this.$store.dispatch('changeMyTasksCount', response.length);
                    await this.$store.dispatch('changeMyTaskUpdatedValue', true);
                }
            } catch (error) {
                // Обрабатываем ошибку
                if (error.name === 'AbortError') {
                    console.log('Fetch request aborted');
                } else {
                    console.error('Fetch request failed:', error);
                }
            }
        },

        loadingComplete(response) {
            this.dataFormated = sortTaskByDate(response);
            console.log(this.dataFormated);

            //Проверка на отсуствие задач
            if (response.length === 0) {
                this.isTasksEmpty = true;
            } else {
                this.isTasksEmpty = false;
            }

            this.isTaskPasses(this.dataFormated, this.isFirstTaskLoad);

            this.task_select_loading = false;
        },

        stayListWithTaskOpen() {
            // Оставляет список с задачами открытым при изменении сокетов
            const key = openActiveTaskList(this.$store.getters.getOpenListName, this.dataFormated);
            if (key) {
                this.dataFormated[key].show = true;
            }
        },

        // Надо подумать о фильтре задач, если будет 22 год, то задачи будут выводиться некорректно
        limitPassTasks(time) {
            let dateNow = Date.now();
            let dateTask = time * 1000;
            return dateTask + 259200000 >= dateNow || dateNow <= dateTask;
        },

        async searchTasks() {
            if (this.deepSearchString) {
                await this.$router.push({ name: 'FoundTasks' });
            }
        },
    },
};
</script>
<style scoped></style>
