import {getObjectCountInTable, getUserObjects} from "@/components/tracker/model/core";
import * as trackerMethod from "@/components/tracker/model/methods"
import {APIFitter} from "@/components/tracker/model/api/fitter";
import {CatalogInteraction} from "@/components/tracker/model/methods";
import {getSensorByHand, getSensorResult} from "@/components/tracker/model/methods/addSensorToObject";


export async function createUnitByHand(api: any) {
    console.log('widget: add object by hand')
    api.loading.createObjectByHandLoading = true;

    // Возвращаю в исходное состояние
    api.createByHandObject.objectInfo.createErrorMessage = "Ошибка при создании"
    api.createByHandObject.objectInfo.isCreateError = false

    interface IParam {
        count?: number,
        type?: string
    }

    interface ISensor {
        name: string,
        param: IParam
    }

    let sensor = [] as ISensor[]

    if (api.formWithoutTable.includes(api.task.form_name)) {
        sensor = getSensorByHand(api)
    } else {
        sensor = getSensorResult(api)
    }

    const sensorsWithoutIgnition = sensor.filter(sens => sens.name !== 'ignition')
    const isPrikur = sensor.filter(sens => sens.name === 'prikur');


    // Если есть прикур и другие датчики, то ошибка
    if (sensorsWithoutIgnition.length > 1 && isPrikur.length > 0) {
        api.$store.dispatch("changeWarningStatus")
        handleCreateObjectError(api, null, 'Прикуриватель не совместим с другими датчиками');

        api.loading.createObjectByHandLoading = false;

        return
    }

    // Прикур засылается сам по себе и зажигание не нужно
    if (isPrikur.length > 0) {
        sensor = sensor.filter(sens => sens.name !== 'ignition')
    }

    const unitName = api.createByHandObject.nameObject ? api.createByHandObject.nameObject.toString() : api.createByHandObject.imei.toString()

    if (unitName.length < 4) {
        // Возвращаю в исходное состояние
        api.createByHandObject.objectInfo.createErrorMessage = "Длина не может быть меньше 4"
        api.createByHandObject.objectInfo.isCreateError = true
        api.loading.createObjectByHandLoading = false;

        return
    }

    const object = {
        host: api.userData.host,
        sensor,
        task_id: api.task.id,
        tracker: {
            number: api.createByHandObject.imei.toString(),
            unit_name: unitName
        }
    }

    try {
        const res = await APIFitter.createUnitsAPI(api, object);

        if (api.formWithoutTable.includes(api.task.form_name)) {
            if (res.status === 200 || res.status === 500) {

                api.createByHandObject.userImeiOrSerial = "";

                if (api.userData.user_id) {

                    const dataFromTable = await trackerMethod.CatalogInteraction.getDataFromTable(api);

                    if (dataFromTable) {

                        getObjectCountInTable(api, dataFromTable);
                        api.unitsData.allUnitsInTable = dataFromTable;

                        console.log('widget: success add (without table)');
                    }

                    await getUserObjects(api);
                    api.closeCreateByHandModal();
                }

            } else {
                api.$store.dispatch("changeWarningStatus")
                handleCreateObjectError(api, res);
            }
        } else {
            await handleCreateObjectSuccess(api, res);
        }
    } catch (error) {
        api.$store.dispatch("changeWarningStatus")
        handleCreateObjectError(api, error);
    }

    api.loading.createObjectByHandLoading = false;
}

async function handleCreateObjectSuccess(api: any, res: any) {
    if (res.status === 200) {
        if (api.userData.user_id) {
            const dataFromTable = await CatalogInteraction.getDataFromTable(api);
            if (dataFromTable) {
                getObjectCountInTable(api, dataFromTable);
                api.unitsData.allUnitsInTable = dataFromTable;
            }
        }
        await getUserObjects(api);
        api.closeCreateByHandModal();
        api.createByHandObject.userImeiOrSerial = "";
    } else {
        handleCreateObjectError(api, res);
    }
}

function handleCreateObjectError(api: any, res: any, text?: string) {
    if (res && res.status === 409 && res.data.body.message.includes("уже существует в ГЛОНАССSoft")) {
        api.createByHandObject.objectInfo.createErrorMessage = res.data.body.message;
    }

    if (text) {
        api.createByHandObject.objectInfo.createErrorMessage = text;
    }

    api.createByHandObject.objectInfo.isCreateError = true;
}