<template>
    <div class="input-message-body"
         id="input-message-body"
         ref="inputMessageBody"
         :style="replyMessage || !accepted ? 'filter: none' : 'filter: drop-shadow(0px -4px 8px rgba(36, 38, 41, 0.1));'">
        <div class="input-message-add-file">
            <label for="file-user" class="input-message--label" style="width: 36px; height: 36px"
            ><img class="input-message-add-file--image" src="@/assets/img/Chat/add-file.svg"
            /></label>
            <input id="file-user" type="file" multiple name="file-user" @change="loadFile"/>
        </div>
        <div class="input-message__textarea">
            <div class="attachment-body" v-if="images.length > 0">

                <div v-for="attachment in images" class="attachment-div">
                    <FilePreviewLink :attachment="attachment" @deleteAttachment="deleteAttachment" />
                </div>
            </div>

            <div class="message-input-block" ref="inputArea">
                <div class="message_placeholder" @click="placeHolderHandler">
                    {{ isPlaceholderVisible ? 'Введите сообщение' : '' }}
                </div>

                <div
                    class="message-input"
                    id="message-input"
                    contenteditable
                    ref="text"
                    @blur="onBlur"
                    @focus="updateRef"
                    @keydown="handleInput"
                    @paste="handlePaste"
                >

                </div>
            </div>

        </div>
        <div>
            <button
                :class="{ buttonActive: message || images.length > 0 }"
                :disabled="isTaskClosed"
                class="button-message"
                @click="postData"
            >
                <img src="@/assets/img/Chat/arrow-up.svg"/>
            </button>
        </div>
    </div>
</template>
<script>
import {postCommentAttachment} from '@/axios_requests/post_attachments'
import {ChatApi} from "@/shared/api/request/chat-api";
import FilePreview from "@/components/chat/message_type/file/helpers/FilePreview";
import {addCommentInLocalStorage} from "@/utils/storage/storage-comment-method";
import {writeMessageInIndexDB} from "../../worker/db.js";
import FilePreviewLink from "@/components/chat/message_type/file/helpers/FilePreviewLink.vue";
import { compressImage } from "@/helpers/fileMethod";
import {addUnsentCommentInLocalStorage, deleteUnsentMessageById} from "@/utils/storage/unsent-comment-method";

export default {
    name: 'MessageInput',
    components: {FilePreviewLink, FilePreview},
    emits: ['placeHolderHandler', 'onBlur', 'updateRef', 'updateMsgText', 'clearInput'],
    props: {
        id: {
            type: Number,
            required: true
        },
        replyMessage: {
            type: Object
        },
        resetReply: {
            type: Function
        },
        isTaskClosed: {
            type: Boolean
        },
        accepted: {
            type: Boolean
        },
        isPlaceholderVisible: {
            type: Boolean
        },
        msgText: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            message: this.$props.msgText,
            replyDOM: '',
            hasLoadedFiles: false,
            fileListPreloader: [],
            loading: false,
            filesId: [],
            isFileLoaded: false,
            request_id: null,
			images: [],
            preview: [],
			imagesForSend: []
        }
    },
    mounted() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', async event => {

                if (event.data.type === 'MESSAGE_SEND_SUCCESS') {
                    if (event.data.data.id === this.id) {
                        // Посмотреть есть ли это сообщение в partMessages, и если нет то добавить
                        await this.$store.dispatch('validateMessagePart', event.data.data.data);
                    }
                }

                if (event.data.type === 'MESSAGE_ERROR') {
                    // Меняю статус на повторить отправку
                   this.showErrorMessageAboutSendingMessage()

                    console.error('Получена ошибка из service worker:', event.data);
                    this.$store.dispatch('changeStatusMessageOnNotSend', {
                        data: event.data.data.data,
                        id: event.data.data.id
                    })
                }
            });
        }

        this.replyDOM = document.querySelector('.message_reply');
    },
    updated() {
        this.replyDOM = document.querySelector('.message_reply')
    },
    computed: {
        isMessageSend() {
            return this.$store.getters.getIsMessageSend
        },
        editMode() {
            return this.$store.getters.getEditMode
        }
    },
    watch: {
		images: function () {
            if (this.replyDOM) {
                if (this.images.length === 0) {
                    this.replyDOM.style.bottom = '100px'
                } else {
                    this.replyDOM.style.bottom = '183px'
                }
            }
        },
        isMessageSend() {
            this.scrollTop()
        },
        editMode() {
            setTimeout(() => {
                if (this.replyDOM && this.$refs.inputMessageBody) {
                    this.replyDOM.style.bottom = 52 + this.$refs.inputMessageBody.offsetHeight + 'px'
                }
            }, 100)
        },
        msgText: {
            handler(newVal) {
                this.message = newVal
            }
        },
    },
    methods: {
        async postData() {
            let strippedString = this.message.replace(/(<([^>]+)>)/gi, "").replace(/\&nbsp;/g, '') + " ";
            let resultText = this.message;

            if (strippedString.trim() === '' && this.images.length === 0) return

            if (this.replyMessage && !this.editMode) {
                resultText = this.validateReplyMessage()
            }

			if (this.message || this.images.length !== 0) {

				const timestamp = new Date().getTime() / 1000

                const images = [...this.images]

				const messageObject = {
					attachments: images, // Сюда массив id
					author_id: Number(this.$store.getters.getCurrentUserId),
					created_at: timestamp,
					updated_at: timestamp,
					id: timestamp,
					info: {},
					is_read: false,
					task_id: this.id,
					text: resultText,
					type: "comment",
					beforeSocket: true,
					isSending: true,
				}


				// Редактирование сообщения
				if (this.editMode) {
					const updatedAttachments = this.$store.getters.getAttachmentsMessage
					const attachmentsIds = updatedAttachments.map(el => el.id)

					const editMessageData = {
						text: resultText,
						attachments: attachmentsIds,
						updated_at: timestamp,
						id: this.$store.getters.getEditCommentId
					}

					await this.$store.dispatch('editMessage', editMessageData)

					const messageSend = await ChatApi.editMessage({
						text: editMessageData.text,
						attachment_ids: attachmentsIds
					}, this.$store.getters.getEditCommentId)

					if (!messageSend) {
						const messageBeforeSend = this.$store.getters.getMessageBeforeEdit

						const notificationError = {
							message: 'Невозможно отредактировать',
							status: true
						}

						await this.$store.dispatch('cancelEditMessage', messageBeforeSend)
						await this.$store.dispatch('setErrorNotification', notificationError)
					}

					this.$emit('clearInput')
					this.onBlur()

					await this.clearStateAfterSendMessage()
					await this.finishMessageSend()

					return
				}

				await this.$store.dispatch('addNewMessagesToStart', messageObject)

				// Обновляю стейт при отправке и скролю вниз к сообщению
				this.scrollTop()
				const statusMessage = this.$store.getters.getIsMessageSend
				await this.$store.dispatch('changeMessageSendStatus', !statusMessage)

				this.$emit('clearInput')

                this.onBlur()

                // Сценарий отправки выглядит так

                // 1. Если приложение оффлайн, то отправка идет сразу через воркеры
                // 2. Иначе идет обычная отправка, если она завершилась ошибкой или истекло время потому что пропал интернет, то отправка через воркеры происходит

                // проверка на онлайн, если подключения нет, то сразу воркеры
                const isAppOnlyne = this.$store.getters.getOnlineStatus

                if (!isAppOnlyne) {
                    this.sendMessageUsingWorker(messageObject)

                    // clear
                    await this.clearStateAfterSendMessage()
                    await this.finishMessageSend()

                    return
                }

                try {
                    // clear
                    await this.clearStateAfterSendMessage()

                    const attachmentsIds = await this.sendFilesAndCollectIds(images)

                    const messageData = {
                        text: resultText,
                        attachment_ids: attachmentsIds,
                    }

                    if (resultText === '' && attachmentsIds.length === 0) return;

                    this.$store.dispatch('clearAttachments')
                    const messageSendingResult = await ChatApi.sendMessage(messageData, this.id.toString())

                    // Если сообщение не отправлено, то пробую через воркер
                    if (!messageSendingResult) {
                        this.sendMessageUsingWorker(messageObject)
                    } else {
                        await this.$store.dispatch('changeMessageToSend', messageObject.id)
                    }
                } catch (err) {
                    this.sendMessageUsingWorker(messageObject)
                }

			}

            await this.finishMessageSend()
        },
		async sendFilesAndCollectIds(images) {
			const attachmentsIds = [];

			if (images.length > 0) {
				const promises = images.map(item => this.sendFile(item.file));
				const results = await Promise.all(promises);

				results.forEach(res => {
					if (res) {
						attachmentsIds.push(res);
					}
				});
			}

			return attachmentsIds;
		},
        sendMessageUsingWorker(messageObject) {
            if ('serviceWorker' in navigator && window.location.hostname !== 'localhost') {
                const messageStore = [{
                    message: messageObject,
                    id: this.id.toString()
                }]

                try {
                    navigator.serviceWorker.controller.postMessage({
                        type: 'SEND_MESSAGE_ACTIVE',
                        data: messageStore
                    });
                } catch (err) {
                    this.showErrorMessageAboutSendingMessage()
                }
            }
        },
        showErrorMessageAboutSendingMessage() {
            const notificationError = {
                message: 'Ошибка при отправке',
                status: true
            }

            this.$store.dispatch('setErrorNotification', notificationError)
        },
        scrollTop() {
            // Определение высоты окна просмотра
            const viewportHeight = window.innerHeight;

            // Определение высоты документа
            const documentHeight = Math.max(
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            );

            // Прокрутка страницы вниз
            window.scrollTo({
                top: documentHeight - viewportHeight,
            });
        },
        placeHolderHandler() {
            this.$emit('placeHolderHandler')
        },
        onBlur() {
            this.updateMsgText()
            this.$emit('onBlur')
        },
        updateRef() {
            this.$emit('updateRef')
        },
        updateMsgText() {
            setTimeout(() => {
                this.$store.dispatch('changeSelectedMessage', null)
                this.$emit('updateMsgText')
            })
        },
        async clearStateAfterSendMessage() {
            this.message = ''

            this.images.length = 0
            this.preview.length = 0
            this.images = []

            // await this.$store.dispatch('clearAttachments')
        },
        async finishMessageSend() {
            this.hasLoadedFiles = false
            this.loading = false
            this.resetReply()

            await this.$store.dispatch('changeEditMode', false)
            await this.$store.dispatch('addMessageInfoBeforeEdit', null)
        },
        validateReplyMessage() {
            let prevMessageFromArr = ""

            const textArr = (this.replyMessage).text.split('/blockquote>')
            if (textArr.length === 1) {
                prevMessageFromArr = textArr[0]
            } else {
                prevMessageFromArr = textArr[textArr.length - 1]
            }

            const replyMessage = this.replyMessage

            const replyAttachments = replyMessage.attachments.length
            const replyAuthor = replyMessage.author_id
            const replyMessageId = replyMessage.id

            return `
                <blockquote data-attachments="${replyAttachments}" data-noteid="${replyMessageId}" data-author="${replyAuthor}"> ${prevMessageFromArr} </blockquote> ${this.message}
            `
        },
        deleteAttachment(filePath) {
            this.$store.dispatch('removeAttachments', filePath)
            this.images = this.images.filter(item => item.url !== filePath)
        },
        input(event) {
            event.target.style.height = '33px'
            event.target.style.height = event.target.scrollHeight + 3 + 'px'
        },

        async sendFile(file) {
            const res = await postCommentAttachment(file);

            if (res) {
				return res.id
            }
        },
		async loadFile(e) {
            const files = e.target.files;

            if (files.length > 0) {

				for (let i = 0; i < files.length; i++) {
					let file = e.target.files[i]

					const image = URL.createObjectURL(file)

					let compressFile = file;

					if (file.type.includes('image')) {
						try {
							compressFile = await compressImage(file);
						} catch (error) {
							console.error('Ошибка при сжатии и отправке изображения:', error);
						}
					}

					this.images.push({
						url: image,
						file: compressFile
					})
				}
            }

            await this.$store.dispatch('addFileInAttachments', this.images)

            e.target.value = "";
        },
        handlePaste(event) {
            event.preventDefault();
            const clipboardData = event.clipboardData || window.clipboardData;
            if (clipboardData) {
                // Получаем текст из буфера обмена без форматирования
                const pastedText = clipboardData.getData('text/plain');
                // Очищаем форматирование и вставляем текст в contenteditable элемент
                this.$refs.text.innerText += pastedText;
            }
        },
        sanitizeContent() {
            // Получаем текст из contenteditable
            const content = this.$refs.text.textContent;

            // Преобразуем HTML-сущности в символы
            const decodedText = this.decodeHtmlEntities(content);

            // Очищаем верстку и вставляем отсанитизированный текст
            const textNode = document.createTextNode(decodedText);
            this.$refs.text.innerHTML = '';
            this.$refs.text.appendChild(textNode);
        },
        moveCaretToEnd() {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(this.$refs.text);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        },
        handleInput(event) {
            // Очищаем верстку после ввода и перемещаем курсор в конец
            // this.sanitizeContent();
            this.updateMsgText();
        },
        decodeHtmlEntities(text) {
            const textarea = document.createElement('textarea');
            textarea.innerHTML = text;
            return textarea.value;
        },
    }
}
</script>
<style scoped>
.input-message-body {
    z-index: 20;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 12px;
    background-color: #ffffff;
    padding: 5px 10px;

    /*filter: drop-shadow(0px -4px 8px rgba(36, 38, 41, 0.1));*/
}

.message-input {
    position: relative;
    top: -2px;

    width: 100%;
    min-height: 36px;

    padding: 4px 16px;

    background: var(--gray-color);
    border: none;
    border-radius: 8px;
    box-shadow: none;
    font-family: 'Montserrat', sans-serif;

    overflow-y: hidden;
    resize: none;
    word-break: break-word;

    font-size: 16px;
    line-height: 150%;
}

.message-input::placeholder {
    position: relative;
    top: 2px;

    display: flex;
    align-items: center;
    vertical-align: center;

    color: #98999e;
    font-size: 14px;
}

.button-message {
    position: relative;
    top: -1px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 36px;
    width: 36px;

    padding: 0;

    transition: all 0.2s;

    border-radius: 50%;
    background-color: #bfbfbf;
    border: none;
}

.button-message img {
    width: 20px;
    height: 20px;
}

.button-message:active {
    transform: translateY(1px);
}

.buttonActive {
    background-color: var(--primary-color);
}

.attachment-body {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 277px;

    overflow-x: scroll;
}

.attachment-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    font-size: 0.9em;
    width: 60px;
    height: 60px;
}


.input-message__textarea {
    width: 100%;
}

#file-user {
    display: none;
}

.loader-tracker {
    animation: preloader-spin 1.5s linear infinite;
}

.input-message-add-file {
    position: relative;
}

.input-message--label {
    position: relative;
    top: -2px;

    display: block;

    width: 30px;
    height: 30px;

    background-color: var(--gray-color);
    border-radius: 50%;
}

.input-message-add-file--image {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 22px;
    height: 22px;

    transform: translate(-50%, -50%);
}

@keyframes preloader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
